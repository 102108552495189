import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect, FormikSelectProps } from 'components';
import { useUserContext, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PaymentType } from 'types';
import { formUtils } from 'utils';

type Props = {
  paymentTypes?: PaymentType[];
} & Omit<FormikSelectProps, 'options' | 'label'> &
  Partial<Pick<FormikSelectProps, 'label'>>;

export const PaymentTypeSelect: React.FC<Props> = ({
  label,
  paymentTypes: paymentTypesProp,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common);
  const { getPaymentTypesOptions } = useUserContextUtils();
  const { paymentTypes } = useUserContext();

  const optionsList = useMemo(
    () => getPaymentTypesOptions(paymentTypesProp || paymentTypes),
    [getPaymentTypesOptions, paymentTypes, paymentTypesProp],
  );

  const options = useMemo(
    () => formUtils.getOptions(optionsList),
    [optionsList],
  );

  return (
    <FormikSelect
      size="small"
      {...rest}
      label={label || t('components.payment_type_select.title')}
      options={options}
    />
  );
};
