import { FormControl, Typography } from '@mui/material';
import { FieldArray, FormikProps } from 'formik';
import { filter, keys, map, omit, pick } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { p2pProvidersApi } from 'api';
import {
  EntityDetailsPage,
  EntityDetailsPageChildrenProps,
  FormikCheckbox,
  FormikMultiSelect,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { P2PProviderType, QueryKey } from 'enums';
import { useCurrencies, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProvider, P2PProviderDto } from 'types';
import { tradeMethodsUtils } from 'utils';

type Values = P2PProviderDto;

export const P2PProviderDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = useMemo(() => id === NEW_ID, [id]);
  const { tradeMethods, banks, paymentTypes, fiatCurrencies } =
    useUserContext();
  const {
    assetCurrenciesOptions,
    fiatCurrenciesOptions,
    defaultAssetCurrency,
    getCurrencyExchangeOptions,
    getAssetCurrencyExchangeOptions,
  } = useCurrencies();

  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const { isTechOperator, isAdmin } = useUser();

  const title = useMemo(
    () => (isNew ? t('details.create_title') : t('details.edit_title')),
    [t, isNew],
  );

  const listUrl = useMemo(() => {
    if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.P2P_PROVIDERS;
    } else if (isAdmin) {
      return ROUTE_PATH.ADMIN.P2P_PROVIDERS;
    }
  }, [isAdmin, isTechOperator]);

  const providerTypeOptions = useMemo(
    () =>
      map(P2PProviderType, (type) => ({
        value: type,
        label: t(`types.${type}`),
      })),
    [t],
  );

  const initialValues = useMemo(
    (): Values => ({
      name: undefined,
      type: P2PProviderType.InternalLike,
      withdrawalFee: 0,
      assetCurrencyId: '',
      fiatCurrencyId: '',
      currencyExchangeId: '',
      payinConfig: {
        url: undefined,
        credentials: {
          apiKey: undefined,
          apiToken: undefined,
          signatureKey: undefined,
          password: undefined,
          apiSecret: undefined,
          apiPublic: undefined,
          apiPrivate: undefined,
          host2host: undefined,
        },
        enabled: false,
        pollingEnabled: false,
        responseTimeout: null,
        expectedFee: 0,
        tradeMethods: [],
        assetCurrencyExchangeId: null,
      },
    }),
    [],
  );

  const getPayinConfigValidationSchema = useCallback(
    (
      schema: Yup.ObjectSchema<Values>,
      options?: { assetCurrencyExchangeIdRequired: boolean },
    ) =>
      schema.shape({
        url: Yup.string().required(tCommon('errors.required')),
        credentials: Yup.object(),
        enabled: Yup.boolean(),
        pollingEnabled: Yup.boolean(),
        responseTimeout: Yup.number().nullable(),
        expectedFee: Yup.number().required(tCommon('errors.required')),
        tradeMethods: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string().required(tCommon('errors.required')),
            }),
          )
          .required(),
        ...(options?.assetCurrencyExchangeIdRequired
          ? {
              assetCurrencyExchangeId: Yup.string().required(
                tCommon('errors.required'),
              ),
            }
          : { assetCurrencyExchangeId: Yup.string().nullable().optional() }),
      }),
    [tCommon],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
        type: Yup.string().oneOf(Object.values(P2PProviderType)),
        withdrawalFee: Yup.number().required(tCommon('errors.required')),
        assetCurrencyId: Yup.string().required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        currencyExchangeId: Yup.string().when('assetCurrencyId', {
          is: (assetCurrencyId: string) =>
            !!assetCurrencyId && assetCurrencyId === defaultAssetCurrency?.id,
          then: (schema) => schema.nullable().optional(),
          otherwise: (schema) => schema.required(tCommon('errors.required')),
        }),
        payinConfig: Yup.object().when('assetCurrencyId', {
          is: (assetCurrencyId: string) =>
            !!assetCurrencyId && assetCurrencyId === defaultAssetCurrency?.id,
          then: (schema) => getPayinConfigValidationSchema(schema),
          otherwise: (schema) =>
            getPayinConfigValidationSchema(schema, {
              assetCurrencyExchangeIdRequired: true,
            }),
        }),
      }),
    [tCommon, defaultAssetCurrency?.id, getPayinConfigValidationSchema],
  );

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   enableReinitialize: true,
  //   validateOnMount: true,
  //   validateOnChange: true,
  //   onSubmit: handleSubmit,
  // });

  const handleTradeMethodsChange = useCallback(
    (formik: FormikProps<Values>, event: any) => {
      const newSelectedTradeMethods = event.target.value;

      formik.setFieldValue(
        'payinConfig.tradeMethods',
        newSelectedTradeMethods.map((id: string) => ({ id })),
      );
    },
    [],
  );

  const resetCurrencyRelatedFields = useCallback(
    (formik: FormikProps<Values>) => {
      formik.setFieldValue('payinConfig.assetCurrencyExchangeId', '');
      formik.setFieldTouched('payinConfig.assetCurrencyExchangeId', true);
      formik.setFieldValue('currencyExchangeId', '');
      formik.setFieldTouched('currencyExchangeId', true);
    },
    [],
  );

  const handleFiatCurrencyChange = useCallback(
    (formik: FormikProps<Values>) => {
      formik.setFieldValue('payinConfig.tradeMethods', []);
      resetCurrencyRelatedFields(formik);
    },
    [resetCurrencyRelatedFields],
  );

  const handleAssetCurrencyChange = useCallback(
    (formik: FormikProps<Values>) => {
      resetCurrencyRelatedFields(formik);
    },
    [resetCurrencyRelatedFields],
  );

  const getFilteredTradeMethods = useCallback(
    (formik: FormikProps<Values>) => {
      const fiatCurrencyId = formik.values.fiatCurrencyId;
      return filter(tradeMethods, {
        fiatCurrencyId,
      });
    },
    [tradeMethods],
  );

  const getTradeMethodsOptions = useCallback(
    (formik: FormikProps<Values>) =>
      tradeMethodsUtils.getTradeMethodOptions({
        tradeMethods: getFilteredTradeMethods(formik),
        banks,
        paymentTypes,
        fiatCurrencies,
      }),
    [getFilteredTradeMethods, banks, paymentTypes, fiatCurrencies],
  );

  const isTradeMethodsSelectDisabled = useCallback(
    (formik: FormikProps<Values>) => {
      const fiatCurrencyId = formik.values.fiatCurrencyId;
      return !fiatCurrencyId || !getTradeMethodsOptions.length;
    },
    [getTradeMethodsOptions],
  );

  const renderCredentials = useCallback(
    (formik: FormikProps<Values>) => (
      <Fragment>
        <Typography>{t('fields.credentials')}</Typography>
        {formik.values.type === P2PProviderType.InternalLike && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_key')}
              name="payinConfig.credentials.apiKey"
            />
            <FormikTextField
              label={t('fields.signature_key')}
              name="payinConfig.credentials.signatureKey"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Trust2Pay && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_secret')}
              name="payinConfig.credentials.apiSecret"
            />
            <FormikTextField
              label={t('fields.password')}
              name="payinConfig.credentials.password"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.X2X && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_secret')}
              name="payinConfig.credentials.apiSecret"
            />
            <FormikTextField
              label={t('fields.password')}
              name="payinConfig.credentials.password"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Payscrow && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_key')}
              name="payinConfig.credentials.apiKey"
            />
            <FormikTextField
              label={t('fields.api_secret')}
              name="payinConfig.credentials.apiSecret"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Namba && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_token')}
              name="payinConfig.credentials.apiToken"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Paycraft && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_private')}
              name="payinConfig.credentials.apiPrivate"
            />
            <FormikTextField
              label={t('fields.api_public')}
              name="payinConfig.credentials.apiPublic"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Exwave && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_token')}
              name="payinConfig.credentials.apiToken"
            />
            <FormikTextField
              label={t('fields.host2host')}
              name="payinConfig.credentials.host2host"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Reactivepay && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_token')}
              name="payinConfig.credentials.apiToken"
            />
            <FormikTextField
              label={t('fields.api_private')}
              name="payinConfig.credentials.privateKey"
            />
          </Fragment>
        )}
      </Fragment>
    ),
    [t],
  );

  const initialValuesSelect = useCallback(
    (data: P2PProvider) => {
      const pickedData = pick(data, keys(initialValues));
      pickedData.payinConfig = data.payinConfig
        ? pick(data.payinConfig, keys(initialValues.payinConfig))
        : initialValues.payinConfig;
      return pickedData;
    },
    [initialValues],
  );

  const onBeforeUpdateMutation = useCallback(
    ({
      id,
      data,
    }: {
      id: string;
      data: Values;
    }): { id: string; data: Values } => ({
      id,
      data: omit(data, ['type', 'assetCurrencyId']),
    }),
    [],
  );

  return (
    <EntityDetailsPage
      title={title}
      id={id!}
      api={p2pProvidersApi}
      apiAsRole={false}
      listUrl={listUrl}
      initialValues={initialValues}
      validationSchema={validationSchema}
      queryKey={QueryKey.P2PProviders}
      formClassName="tw-max-w-md"
      initialValuesSelect={initialValuesSelect}
      onBeforeUpdateMutation={onBeforeUpdateMutation}
    >
      {({
        formik,
        queryResult,
      }: EntityDetailsPageChildrenProps<Values, P2PProvider>) => (
        <Fragment>
          <Typography variant="h5">{t('details.common_details')}</Typography>
          <FormikTextField label={t('fields.name')} name="name" required />
          <FormikSelect
            label={t('fields.type')}
            name="type"
            options={providerTypeOptions}
            disabled={!!queryResult.data}
            required
          />
          <FormikNumericField
            label={t('fields.withdrawal_fee')}
            allowNegative={false}
            decimalScale={0}
            name="withdrawalFee"
            suffix={defaultAssetCurrency?.symbol}
            fullWidth
            required
          />
          <FormikSelect
            label={t('fields.fiatCurrencyId')}
            name="fiatCurrencyId"
            options={fiatCurrenciesOptions}
            onChange={() => handleFiatCurrencyChange(formik)}
            required
          />
          <FormikSelect
            label={t('fields.assetCurrencyId')}
            name="assetCurrencyId"
            options={assetCurrenciesOptions}
            onChange={() => handleAssetCurrencyChange(formik)}
            disabled={!!queryResult.data}
            required
          />
          {formik.values.assetCurrencyId &&
            defaultAssetCurrency?.id !== formik.values.assetCurrencyId && (
              <FormikSelect
                label={t('fields.currency_exchange')}
                helperText={t('fields.currency_exchange_description')}
                name="currencyExchangeId"
                required
                options={getCurrencyExchangeOptions({
                  fiatCurrencyId: formik.values.fiatCurrencyId,
                  assetCurrencyId: formik.values.assetCurrencyId,
                })}
              />
            )}

          <Typography variant="h5">{tCommon('common.payin')}</Typography>
          <FormControl sx={{ alignSelf: 'flex-start' }}>
            <FormikCheckbox
              label={t('fields.enabled')}
              name="payinConfig.enabled"
            />
          </FormControl>
          <FormControl sx={{ alignSelf: 'flex-start' }}>
            <FormikCheckbox
              label={t('fields.polling_enabled')}
              name="payinConfig.pollingEnabled"
            />
          </FormControl>

          {formik.values.assetCurrencyId &&
            formik.values.assetCurrencyId !== defaultAssetCurrency?.id && (
              <FormikSelect
                label={t('fields.withdrawal_currency_exchange')}
                name="payinConfig.assetCurrencyExchangeId"
                required
                helperText={t(
                  'fields.withdrawal_currency_exchange_description',
                )}
                options={getAssetCurrencyExchangeOptions(
                  formik.values.assetCurrencyId,
                )}
                noneOption
              />
            )}
          <FormikTextField
            label={t('fields.url')}
            name="payinConfig.url"
            required
          />
          {renderCredentials(formik)}
          <FormikNumericField
            label={t('fields.response_timeout')}
            name="payinConfig.responseTimeout"
            suffix={tCommon('suffixes.ms')}
            allowNegative={false}
            fullWidth
            helperText={t('fields.response_timeout_description')}
          />
          <FormikNumericField
            label={t('fields.expected_fee')}
            name="payinConfig.expectedFee"
            allowNegative={false}
            percentageSuffix
            fullWidth
            required
          />

          <FieldArray
            name="payinConfig.tradeMethods"
            render={() => {
              const multiSelectValue =
                formik.values.payinConfig?.tradeMethods?.map(({ id }) => id) ||
                [];

              return (
                <FormikMultiSelect
                  label={t('fields.trade_methods')}
                  name="payinConfig.tradeMethods"
                  options={getTradeMethodsOptions(formik)}
                  value={multiSelectValue}
                  onChange={(event) => handleTradeMethodsChange(formik, event)}
                  disabled={isTradeMethodsSelectDisabled(formik)}
                />
              );
            }}
          />
        </Fragment>
      )}
    </EntityDetailsPage>
  );
};
