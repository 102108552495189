export enum QueryKey {
  Agents = 'agents',
  Profile = 'profile',
  TwoFA = 'two-fa',
  Users = 'users',
  Merchants = 'merchants',
  UsersMerchants = 'users-merchants',
  UserInvite = 'user-invite',
  Assets = 'assets',
  MyAssets = 'my-assets',
  TradersAssets = 'traders-assets',
  MerchantsAssets = 'merchants-assets',
  PlatformAssets = 'platform-assets',
  MyInfo = 'my-info',
  ShopAssets = 'shop-assets',
  FundsRequests = 'funds-requests',
  InternalTransfers = 'internal-transfers',
  MyFundsRequests = 'my-funds-requests',
  Shops = 'shops',
  Requisites = 'requisites',
  RequisitesGroups = 'requisites-groups',
  RequisitesGroupsPaginated = 'requisites-groups-paginated',
  RequisitesTelegramAutomation = 'requisites-telegram-automation',
  PayoutRequisites = 'payout-requisites',
  UserReferrals = 'user-referrals',
  ShopReferrals = 'shop-referrals',
  Order = 'order',
  ActiveOrders = 'active-orders',
  CompletedOrders = 'completed-orders',
  CancelledOrders = 'cancelled-orders',
  ExportOrdersColumns = 'export-orders-columns',
  ActivePayoutOrders = 'active-payout-orders',
  CompletedPayoutOrders = 'completed-payout-orders',
  CancelledPayoutOrders = 'cancelled-payout-orders',
  DisputePayoutOrders = 'dispute-payout-orders',
  ExportPayoutOrdersColumns = 'export-payout-orders-columns',
  PayinOrderReceipts = 'payin-order-receipts',
  PayoutOrderReceipts = 'payout-order-receipts',
  DisputeOrders = 'dispute-orders',
  TraderDisputeOrders = 'trader-dispute-orders',
  ProviderDisputeOrders = 'provider-dispute-orders',
  Traders = 'traders',
  UsersTraders = 'users-traders',
  Notifications = 'notifications',
  AssetBalanceHistory = 'asset-balance-history',
  CurrencyExchangeRates = 'currency-exchange-rates',
  CurrencyExchange = 'currency-exchange',
  FiatCurrencyExchange = 'fiat-currency-exchange',
  AssetCurrencyExchange = 'asset-currency-exchange',
  AssetCurrencyExchangeRequests = 'asset-currency-exchange-requests',
  UserAssetCurrencyExchange = 'user-asset-currency-exchange',
  Groups = 'groups',
  RequisitesDistribution = 'requisites-distribution',
  AssetCurrencies = 'asset-currencies',
  FiatCurrencies = 'fiat-currencies',
  Banks = 'banks',
  TradeMethods = 'trade-methods',
  TradeMethodPayoutPDFTemplates = 'trade-method-payout-pdf-templates',
  ParsingMethods = 'parsing-methods',
  ParsingMethodsParse = 'parsing-methods-parse',
  PaymentTypes = 'payment-types',
  Meta = 'meta',
  Operators = 'operators',
  OrderExistsByRequisites = 'order_exists_by_requisites',
  PayoutOrderExistsByRequisites = 'payout_order_exists_by_requisites',
  NewAnomalies = 'new_anomalies',
  AnomaliesLastSearchAt = 'anomalies_last_search_at',
  ReviewedAnomalies = 'reviewed_anomalies',
  ActiveOrderAutomation = 'active-order-automation',
  ArchiveOrderAutomation = 'archive-order-automation',
  AllOrderAutomation = 'all-order-automation',
  ErrorOrderAutomation = 'error-order-automation',
  ErrorReviewedOrderAutomation = 'error-reviewed-order-automation',
  TemplateErrorOrderAutomation = 'template-error-order-automation',
  TemplateErrorHandledOrderAutomation = 'template-error-handled-order-automation',
  TechnicalOrderAutomation = 'technical-order-automation',
  AutomationSources = 'automation-sources',
  Webhooks = 'webhooks',
  WebhooksHistory = 'webhooks-history',
  AgentsStatisticSummary = 'agents-statistic-summary',
  SummaryStatistic = 'summary-statistic',
  DailySummaryStatistic = 'daily-summary-statistic',
  RequisitesStatistic = 'requisites-statistic',
  DailyRequisitesStatistic = 'daily-requisites-statistic',
  OrderStatusHistory = 'order-status-history',
  InternalUsersWallets = 'internal-users-wallets',
  InternalPlatformWallets = 'internal-platform-wallets',
  InternalPlatformWalletConfig = 'internal-platform-wallet-config',
  InternalP2PProviderWallets = 'internal-p2p-providers-wallets',
  InternalWalletsArchive = 'internal-wallets-archive',
  InternalWalletTransactionsDepositActive = 'platform-wallet-transactions-deposit-active',
  InternalWalletTransactionsDepositArchive = 'platform-wallet-transactions-deposit-archive',
  InternalWalletTransactionsWithdrawalActive = 'platform-wallet-transactions-withdrawal-active',
  InternalWalletTransactionsWithdrawalArchive = 'platform-wallet-transactions-withdrawal-archive',
  OrderCashback = 'order-cashback',
  SciDomains = 'sci-domains',
  UserAudit = 'user-audit',
  PayoutOrderStatusHistory = 'payout-order-status-history',
  BalanceReconciliationPlatform = 'balance-reconciliation-platform',
  BalanceReconciliationUsers = 'balance-reconciliation-users',
  UserWallets = 'user-wallets',
  P2PProviderPayinWebhooks = 'p2p-provider-payin-order-webhooks',
  P2PProviderPayinRequests = 'p2p-provider-payin-order-requests',
  P2PProviders = 'p2p-providers',
  P2PProvidersArchive = 'p2p-providers-archive',
  P2PProvidersBalances = 'p2p-providers-balances',
  BlockedCustomers = 'blocked-customers',
  ExecutionMetrics = 'execution-metrics',
}
